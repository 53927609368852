import React, {useContext, useEffect, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import "../../utils/fix-map-icon";
import {SearchContext} from "../../contexts/search.context";
import {SimpleAdEntity} from 'types';

import "leaflet/dist/leaflet.css";
import "./Map.css";
import {SingleAd} from "./SingleAd";
import {apiUrl} from "../../config/api";


export const Map = () => {
    const {search} = useContext(SearchContext);
    const [ads, setAds] = useState<SimpleAdEntity[]>([]);

    useEffect(() => {
        (async() => {
            const data = await fetch(`${apiUrl}/ad/search/${search}`);
            const res = await data.json();
            setAds(res);
        })();
    }, [search]);

  return (
      <div className="map">
        <MapContainer center={[52.3866032,16.9156088]} zoom={20}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>Open Street Map</a> & contributors"
          />
            {

                ads.map(ad => (
                    <Marker key={ad.id} position={[ad.lat, ad.lon]}>
                        <Popup>
                            <SingleAd id={ad.id}></SingleAd>
                        </Popup>
                    </Marker>
                ))
            }

        </MapContainer>
      </div>
  );
};